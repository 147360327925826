<template>
  <footer class="containers-fluid Footer">
    <div class="containers">
      <div class="ch-row a-center">
        <div class="ch-col-6 col-md-12 text-left follow">
          <span v-bind:style="`background-image:url(` + logoImg + `)`" class="logo"></span>
          <div>
            <p class="location">098-159-8878 (Cheng)</p>
            <p class="location">hello@codehard.co.th</p>
            <p class="location mb-3">52/475, Moo 9 Soi 16, Bang Phut,
              Pak Kret, Nonthaburi 11120</p>
            <ul>
              <li>
                <a href="https://www.tiktok.com/@codehardth" target="_blank" aria-label="CodeHard">
                  <img src="@/assets/images/follow/tiktok.png" class="icon" alt="CodeHard" :height=25 width="100%" />
                </a>
              </li>
              <li>
                <a href="https://web.facebook.com/codehardth" target="_blank" aria-label="CodeHard">
                  <img src="@/assets/images/follow/fac.png" class="icon" alt="CodeHard" :height=25 width="100%" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/codehardth/" target="_blank" aria-label="CodeHard">
                  <img src="@/assets/images/follow/in.png" class="icon" alt="CodeHard" :height=25 width="100%" />
                </a>
              </li>
              <li>
                <a href="https://github.com/codehardth" target="_blank" aria-label="CodeHard">
                  <img src="@/assets/images/follow/git.png" class="icon" alt="CodeHard" :height=25 width="100%" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/codehardth/" target="_blank" aria-label="CodeHard">
                  <img src="@/assets/images/follow/ig.png" class="icon" alt="CodeHard" :height=25 width="100%" /></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="ch-col-6 col-md-12 text-left">
          <a href="https://www.google.com/maps/place/%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97+%E0%B9%82%E0%B8%84%E0%B9%89%E0%B8%94%E0%B8%AE%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%94+%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94+Code(Hard);/@13.9226898,100.5343607,19z/data=!3m1!4b1!4m6!3m5!1s0x30e283aaca5d93cf:0xcc3ac37d20929f95!8m2!3d13.9226898!4d100.5349079!16s%2Fg%2F11rp2gk9sc"
            target="_blank" aria-label="CodeHard">
            <span v-bind:style="`background-image:url(` + img + `)`" class="mask"></span>
          </a>
        </div>
      </div>
      <span class=" hr"></span>
      <div class="containers">
        <p class="copyright">Copyright © Code(Hard); Company & technology solution business developer consulting v.1.04
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import mapsImg from '@/assets/images/Mask.webp';
import logoImg from '@/assets/logo-light.webp';
export default {
  data() {
    return {
      img: mapsImg,
      logoImg: logoImg
    }
  },
};
</script>

<style lang="scss" scoped>
.Footer {
  background-color: #3B0516;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  color: #fff;
  font-weight: 200;
  position: absolute;
  left: 0;
  // height: 427px;
  width: 100%;
  bottom: -500px;

  .hr {
    display: block;
    background-color: rgba(255, 255, 255, .2);
    height: 2px;
    width: 100%;
    margin: .5rem 0;
  }

  .follow {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 2rem;

    .icon {
      height: 25px;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 0.5rem;
    }

    .logo {
      height: 70px;
      width: 140px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: block;
    }

  }


  .logo {
    width: 150px;
    height: auto;
  }

  .mask {
    width: 100%;
    height: 295px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
  }

  .location {
    font-size: .8rem;
  }

  .copyright {
    font-size: .7rem;
    text-align: center;
  }
}

@media screen and (max-width: 880px) {
  .Footer {
    position: relative;
    height: auto;
    bottom: unset;

    .location {
      margin-top: .5rem;
      text-align: left;
    }

    .follow {
      padding: 1rem;
    }
  }
}
</style>