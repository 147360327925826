import { reactive } from "vue";

export const state = reactive({
    isShow: true,
    showLoading() {
        this.isShow = true;
    },
    hideLoading() {
        this.isShow = false;
    }
});